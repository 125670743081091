body {
  margin: 0;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}

.nav-link{
  color: white;
}

.nav-link{
  color: white !important;
  font-weight: bold;
}

.navbar-brand{
  color: white !important;
  
}

.nav-link.tryout {
  color: white !important;
  background-color: #B41C32;  
  transition: all 0.2s ease-in-out;
}

.nav-link.tryout:hover {
  background-color: #9D4F5A;
  box-shadow: 0 0 50px #B41C32;
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  /* Add any other hover styles here */
}

.nav-link.disabled {
  color: black !important;
  background-color: white; 
  font-weight: bolder; 
}

.markdown {
  font-family: Arial, sans-serif;
  font-size: 16px;
  line-height: 1.6;
  padding: 16px;
}